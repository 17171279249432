<template>
    <div class="collection-layout" :class="hasCenterParent ? 'background-white' : 'background-grey'">
        <HeroBlog v-if="heroBlog" v-bind="heroBlog" />

        <HeroBasic
            v-if="programProjectHero"
            :heading="programProjectHero.title"
            :eyebrow="programProjectHero.eyebrowText"
            :heroImageUrl="programProjectHero.heroImage?.url"
            :logo="programProjectHero.logo?.url"
            :isTall="true"
            :hasHeaderOffset="true"
        />

        <SubnavBlock
            :navigationLinks="subNav?.navigationLinks"
            :socialNetworks="subNav?.socialNetworks ?? socialNetworks"
            :button="subNav?.button"
        />

        <HeroBasic
            :heading="title"
            accentColor="grey"
            class="page-hero"
            :showTopographic="hasCenterParent"
            :hasHeaderOffset="hasCenterParent"
            :richText="dek"
            :isSansSerif="true"
            :eyebrow="hasCenterParent ? 'collection' : ''"
            :eyebrowTag="true"
        >
            <template #headerDetails>
                <div class="header-details">
                    <AvatarSet
                        v-if="nonArchivedContributors?.length"
                        :people="nonArchivedContributors"
                        class="credits"
                    ></AvatarSet>
                    <div class="controls">
                        <div class="social-share controls-item">
                            <ShareButton />
                            <CopyLinkButton />
                        </div>
                    </div>
                </div>
            </template>
        </HeroBasic>

        <StreamTierFeed :streamTiered="streamTiered" />

        <IndexTier :contentType="contentType" :pageId="id" :hasFilters="false" :hideResultCount="true" :limit="12">
            <template #results="{ results }">
                <div class="results-wrapper">
                    <ContentCardBlock
                        v-for="result in results"
                        :key="result.id"
                        :related="result"
                        size="33"
                        :isProportionate="true"
                        class="result"
                        :showPublicationDate="true"
                    />
                </div>
            </template>
        </IndexTier>
    </div>
</template>

<script setup>
const props = defineProps({
    dek: {
        type: Object,
    },
    contentType: {
        type: String,
    },
    id: {
        type: String,
    },
    title: {
        type: String,
    },
    streamTiered: {
        type: Array,
    },
    contributors: {
        type: Array,
    },
    imageCollage: {
        type: Array,
    },
    parent: {
        type: Object,
    },
    path: {
        type: String,
    },
    locale: {
        type: String,
        default: '',
    },
});
const nonArchivedContributors = computed(() => {
    return props.contributors.filter((data) => !data.isArchived);
});

const hasCenterParent = computed(() => {
    return props.parent?.value?.__typename?.toLowerCase() === 'center';
});

const heroBlog = computed(() => {
    if (props.parent?.value?.heroBlog) {
        return {
            title: props.parent?.value?.title,
            ...props.parent?.value?.heroBlog,
        };
    }
});

const programProjectHero = computed(() => {
    if (props.parent?.value?.heroProgramsProjects) {
        return {
            title: props.parent?.value?.title,
            eyebrowText: props.parent?.value?.__typename,
            ...props.parent?.value?.heroProgramsProjects,
        };
    }
});

const subNav = computed(() => {
    return props.parent?.value?.subNav || null;
});

const headerAttributesStore = useHeaderAttributesStore();

const GTMCenter = useGTM();
const GTMLang = props.locale;

onMounted(() => {
    window.dataLayer.push({
        Center: GTMCenter.GTMCenter,
        Doctype: 'Collection',
        Expert: '',
        'Taxonomy Tags Program': '',
        'Taxonomy Special Project': '',
        'Taxonomy Tags Regions': '',
        'Taxonomy Tags Topics': '',
        'Page Language': GTMLang,
        event: 'new_data_layer',
    });
    headerAttributesStore.updateHeaderStyleAttributes({ background: 'white' });
});
</script>

<style lang="scss" scoped>
.subnav,
.background-grey {
    background-color: color(grey, light);
}

.background-white {
    background-color: color(blanc);
}

.header-details {
    display: flex;
    max-width: 77rem;
    gap: var(--col-gutter);
    flex-wrap: wrap;
    margin-top: vertical-space(1);
    :deep(.names) {
        align-items: center;
        display: flex;
    }
}

.controls {
    margin-top: vertical-space(1);
    display: flex;
    gap: 0.8rem;
    align-items: center;
}

.social-share {
    margin-inline-start: 0.8rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
}

.results-wrapper {
    @include media-query(full) {
        display: grid;
        gap: var(--col-gutter);
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .result {
        background: none;
        padding: 0;

        @include media-query(mobile) {
            &:not(:first-child) {
                margin-top: var(--col-gutter);
            }
        }
    }
}
</style>
